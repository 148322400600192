// Dependency
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import axios from '../../utils/axios'
import {useDispatch, useSelector} from 'react-redux'
import {pageActionsTypes} from '../../redux/actions/currentPage/pageActionTypes'
import cls from './companresearcherListItem.module.scss'
import config from '../../config/defaultSettings'
import oficialAxios from 'axios'

// Service model
import ClientGetInfo from '../../models/globalVariables'
import Respondent from '../../models/respondent'
import Inquiry from '../../models/inquiry'

// Components
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumb'
import Filter from '../../components/Filter'
import {StyledTab, StyledTabs} from '../../components/StyledTabs'
import {TabPanel} from '../../components/Tab/TabBody'
import ClientInfo from '../../components/ClientInfo/clientInfo'
import TableIndex from '../../components/itemResearcherTable/TableIndex'

// Functions
import {NumberFomat} from '../../functions/numberFomat'
import ClientCalendar from '../../components/ClientInfo/clientCalendar'
import InfoTable from '../../components/TolokaInfoTable/InfoTable'

const CompanresearcherListItem = () => {
  const currentTab = useSelector((state) => state.counter.childCurrentTab)
  const dispatch = useDispatch()
  const param = useParams()
  const {t} = useTranslation()
  const [status, setStatus] = useState(0)
  const [tabvalue, settabvalue] = useState(0)
  const [dataSourse, setdataSourse] = useState([])
  const [clientInfoData, setclientInfoDate] = useState([])
  const [getInquiry, setGetInquiry] = useState(false)
  const [duration, setDuration] = useState()
  const [cost, setCost] = useState(0)
  const [items, setItems] = useState({})
  const [partisipants, setPartisipants] = useState(0)
  const [respondents, setRespondents] = useState(0)
  const pageCount = 10
  const [userName, setUserName] = useState(null)
  const [pageLimit, setPageLimit] = useState(10)
  const [toloka, setToloka] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [getId, setGetId] = useState({
    survey_id: '',
    respondent_id: '',
  })

  const tabLabel = (text, isActive = false) => {
    return <span className='px-1'>{text}</span>
  }
  const backgroundColorTab = (text) => {
    switch (text) {
      case 'В ожидании':
        return 'linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF'
      case 'Отмененные':
        return 'linear-gradient(0deg, rgba(247, 102, 89, 0.15), rgba(247, 102, 89, 0.15)), #FFFFFF'
      case 'Подтвержденный':
        return 'linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF'
      case 'Завершенные':
        return 'linear-gradient(0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), #FFFFFF'
      default:
        return '#fff'
    }
  }
  const statusLabel = (text, num, isActive) => {
    return (
      <div
        style={{
          display: 'flex',
          background: isActive ? backgroundColorTab(text) : 'transparent',
          borderRadius: '6px',
          padding: '4px 16.5px',
        }}
      >
        <span
          style={{
            color: `${
              text === 'В ожидании'
                ? '#D29404'
                : text === 'Отмененные'
                ? '#F76659'
                : text === 'Подтвержденный'
                ? '#4094F7'
                : text === 'Завершенные'
                ? '#1AC19D'
                : '#4094F7'
            }`,
          }}
          className='px-1'
        >
          {text}
        </span>
        <span
          style={{
            color: `${
              text === 'В ожидании'
                ? '#D29404'
                : text === 'Отмененные'
                ? '#F76659'
                : text === 'Подтвержденный'
                ? '#4094F7'
                : text === 'Завершенные'
                ? '#1AC19D'
                : '#4094F7'
            }`,
          }}
          className='px-1'
        >
          {num}
        </span>
      </div>
    )
  }
  const handleSubmit = (data) => {}

  let texts =
    'https://cdn.urecruit.ru/photos/7ba4aa7d-debd-41d8-ab41-23e113800ad1+2-laborotory.doc'

  useEffect(() => {
    settabvalue(currentTab === undefined ? tabvalue : currentTab.tab)
  }, [])
  
  useEffect(() => {
    console.log('currentTab',currentTab)
  }, [currentTab])

  useEffect(() => {
    console.log('currentPage',currentPage)
  }, [currentPage])



  const [columnName, setcolumnName] = useState([
    t('number.icon'),
    t('full.name'),
    t('Phone'),
    t('email.name'),
    // t("Рейтинг"),
    t('status'),
    ' ',
  ])
  const routes = [
    {
      title: 'Запросы',
      link: true,
      route: `${'/home/company/companyinquiry'}/${param.id}`,
    },
    {
      title: userName === undefined ? 'User' : userName,
      link: true,
    },
  ]
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue)
    dispatch({
      type: pageActionsTypes.CHILD_CURRENT_TAB,
      payload: {
        tab: newvalue,
      },
    })
  }
  const formik = useFormik({
    initialValues: {
      lastname: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape({
      lastname: Yup.string().strict(true).required('это требовалось'),
    }),
  })

  const renderErrorMessage = (field) => {
    return (
      formik.touched[field] && (
        <div class='text-error'>{formik.errors[field]}</div>
      )
    )
  }
  const selectStatusId = (num) => {
    switch (num) {
      case 0:
        return '01ee5c38-5f56-4e72-9b5a-926e4adce26a'
      case 1:
        return 'e86c9b5b-5c71-48c8-b61f-42eeb51e33c6'
      case 2:
        return '2e857fbe-9add-4eae-a8c4-fe57fb384347'
      case 3:
        return '2a98c22e-cbee-49f9-90ef-f56429d96333'
      default:
        return ''
    }
  }
  const getRespondents = () => {
    const params = {
      inquiry_id: param.inquiry_id,
      page: pageLimit / pageCount,
      limit: 10,
      status_id: selectStatusId(status),
    }
    Inquiry.getInquiryRespondents(params).then((res) => {
      setPartisipants(res.data.number_of_partisipants)
      setRespondents(res.data.finished_respondents)
      setdataSourse(res.data)
      setItems(res.data)
    })
  }

  const clientinfo = (id) => {
    ClientGetInfo.clientInfo(param.inquiry_id).then((res) => {
      setDuration(res.data.duration)
      setGetId({
        ...getId,
        survey_id: res.data.screener_survey_id,
      })
      getCost(res.data)
      setUserName(res.data.title)
      setclientInfoDate([res.data])
    })
  }
  const [allTabs, setAllTabs] = useState(null)
  const getTabs = () => {
    axios
      .get(`/inquiry/tab?inquiry_id=${param.inquiry_id}`)
      .then((res) => {
        setAllTabs(res.data)
      })
      .catch((err) => console.log(err))
  }
  const getCost = (props) => {
    axios
      .get(
        `/inquiry/calculate_cost?partisipant_role=${
          props.partisipant_role
        }&duration=${props.duration}&number_of_partisipants=${
          props.number_of_partisipants
        }&is_online=${props.interview_type === 'online' ? true : false}`
      )
      .then((res) => {
        setCost(res.data.total_cost)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getToloka = () => {
    const params = {
      sort: 'created',
      pool_id: clientInfoData[0]?.pool_id,
      // status: "ACTIVE",
    }
    axios
      .get('/toloka/responses', {
        params,
      })
      .then((res) => {
        console.log('res', res)
        setToloka(JSON.parse(res.data))
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  useEffect(() => {
    clientInfoData[0]?.pool_id && getToloka()
    clientinfo(param.researcheId)
    getRespondents()
    getTabs()
  }, [clientInfoData[0]?.pool_id])
  useEffect(() => {
    if (getInquiry) {
      getRespondents()
      setGetInquiry(false)
    }
  }, [getInquiry])

  useEffect(() => {
    getRespondents()
  }, [status,pageLimit])

  // useEffect(() => {
  //   console.log('status',status)
  // }, [status])

  // useEffect(() => {
  //   console.log('pageLimit', pageLimit, items)
  // }, [pageLimit, items])

  return (
    <div>
      <Header startAdornment={[<Breadcrumbs routes={routes} />]} />
      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label='full width tabs example'
          TabIndicatorProps={{children: <span className='w-2' />}}
        >
          <StyledTab
            label={tabLabel(t('company.info'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
          <StyledTab
            label={
              <div className={cls.respondents_tabBlock}>
                <div className={cls.respondents_lable}>
                  {tabLabel('Респонденты')}
                </div>
                {allTabs?.pending ? (
                  <div className={cls.respondents__index}>
                    {allTabs?.pending}
                  </div>
                ) : null}
              </div>
            }
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
          {clientInfoData[0]?.study_type !== 'toloka' && (
            <StyledTab
              label={tabLabel(t('Календарь'))}
              {...a11yProps(0)}
              style={{width: '100px'}}
            />
          )}
        </StyledTabs>
      </Filter>
      {tabvalue === 1 && clientInfoData[0]?.study_type !== 'toloka' && (
        <div id='tab_status'>
          <Filter status={true}>
            <StyledTabs
              value={status}
              onChange={(event, newValue) => {
                setStatus(newValue)
                setPageLimit(10)
                setCurrentPage(1)
              }}
              centered={false}
              aria-label='full width tabs example'
              TabIndicatorProps={{children: <span className='w-2' />}}
            >
              <StyledTab
                label={statusLabel(
                  t('pending'),
                  allTabs?.pending,
                  Boolean(status === 0)
                )}
                {...a11yProps(0)}
                style={{width: '200px'}}
              />
              <StyledTab
                label={statusLabel(
                  t('confirm'),
                  allTabs?.confirmed,
                  Boolean(status === 1)
                )}
                {...a11yProps(0)}
                style={{width: '200px'}}
              />
              <StyledTab
                label={statusLabel(
                  t('done'),
                  allTabs?.finished,
                  Boolean(status === 2)
                )}
                {...a11yProps(0)}
                style={{width: '200px'}}
              />
              <StyledTab
                label={statusLabel(
                  t('canceliation'),
                  allTabs?.canceled,
                  Boolean(status === 3)
                )}
                {...a11yProps(0)}
                style={{width: '200px'}}
              />
            </StyledTabs>
          </Filter>
        </div>
      )}
      <div>
        <TabPanel value={tabvalue} index={0}>
          <ClientInfo
            dataSourse={dataSourse}
            NumberFormat={NumberFomat}
            clientInfoData={clientInfoData}
            getId={getId}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          {clientInfoData[0]?.study_type === 'toloka' ? (
            <InfoTable
              getTabs={getTabs}
              getRespondents={getRespondents}
              pageLimit={pageLimit}
              items={items}
              toloka={toloka}
              pageCount={pageCount}
              setPageLimit={setPageLimit}
              statusTab={status}
              getId={getId}
              setGetInquiry={setGetInquiry}
              dataSourse={dataSourse}
              setdataSourse={setdataSourse}
              column={columnName}
              clientInfoData={clientInfoData}
            />
          ) : (
            <TableIndex
              getTabs={getTabs}
              getRespondents={getRespondents}
              pageLimit={pageLimit}
              items={items}
              pageCount={pageCount}
              setPageLimit={setPageLimit}
              setCurrentPage={setCurrentPage}
              getId={getId}
              setGetInquiry={setGetInquiry}
              dataSourse={dataSourse}
              setdataSourse={setdataSourse}
              column={columnName}
              currentPage={currentPage}
            />
          )}
        </TabPanel>
        {clientInfoData[0]?.study_type !== 'toloka' && (
          <TabPanel value={tabvalue} index={2}>
            <ClientCalendar duration={duration} />
          </TabPanel>
        )}
      </div>
    </div>
  )
}

export default CompanresearcherListItem
